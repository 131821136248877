import React, { lazy } from 'react';
import {inscrierePageMenuOld,dashboardOLD,Dashboards,dashboardInformaticianPagesMenu,dashboardInspectorPagesMenu, inscrierePageMenu, dashboardPagesMenu, adminPageMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	DASHBOARD_INS: lazy(() => import('../pages/presentation/dashboardInspector/DashboardInspectorPage')),
	DASHBOARD_INF: lazy(() => import('../pages/presentation/informatician_page/DashboardInformatician')),

	INSCRIERE_OLD: lazy(() => import('../pages/presentation/inscriere/InscrierePageOld')),
	ADMIN: lazy(() => import('../pages/presentation/admin_page/AdminPage')),
	INSCRIERE: lazy(() => import('../pages/presentation/inscriere/InscrierePage')),

	INSCRIERE_CS: lazy(() => import('../pages/presentation/inscriere/InscriereCS')),
	INSCRIERE_TULCEA: lazy(() => import('../pages/presentation/inscriere/InscriereTulcea')),
	INSCRIERE_IASI: lazy(() => import('../pages/presentation/inscriere/InscriereIasi')),

	//Ultinmele route facut pentru multiisj
	MRU_DASHBOARD: lazy(() => import('../pages/presentation/dashboard/Dashboard_MRU')),
	ADMIN_DASHBOARD: lazy(() => import('../pages/presentation/dashboard/Dashboard_Admin')),
	INFO_DASHBOARD: lazy(() => import('../pages/presentation/dashboard/Dashboard_Informatician')),
	INSP_DASHBOARD: lazy(() => import('../pages/presentation/dashboard/Dashboard_Inspector')),
	CAND_DASHBOARD: lazy(() => import('../pages/presentation/dashboard/Dashboard_Candidat')),
	OLD_DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPageOld')),



};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};


const presentation = [
	/**
	 * Landing
	 */
	// routes noi pentru multi Isj
	{
		path: dashboardOLD.dashboardOld.path,
		element: <LANDING.OLD_DASHBOARD />,
	},
	{
		path: Dashboards.MRU.path,
		element: <LANDING.MRU_DASHBOARD />,
	},
	{
		path: Dashboards.ADMIN.path,
		element: <LANDING.ADMIN_DASHBOARD />,
	},
	{
		path: Dashboards.INSPECTOR.path,
		element: <LANDING.INSP_DASHBOARD />,
	},
	{
		path: Dashboards.INFORMATICIAN.path,
		element: <LANDING.INFO_DASHBOARD />,
	},
	{
		path: Dashboards.CANDIDAT.path,
		element: <LANDING.CAND_DASHBOARD />,
	},
	{
		path: inscrierePageMenu.inscriere.path,
		element: <LANDING.INSCRIERE/>,
	},
	{
		path: "/Inscriere/Inscriere-CS",
		element: <LANDING.INSCRIERE_CS/>,
	},
	{
		path: "/Inscriere/Inscriere-Tulcea",
		element: <LANDING.INSCRIERE_TULCEA/>,
	},	{
		path: "/Inscriere/Inscriere-Iasi",
		element: <LANDING.INSCRIERE_IASI/>,
	},
	//terminat 
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: dashboardInspectorPagesMenu.dashboard_insp.path,
		element: <LANDING.DASHBOARD_INS />,
	},
	{
		path: dashboardInformaticianPagesMenu.dashboard_info.path,
		element: <LANDING.DASHBOARD_INF />,
	},
	{
		path: inscrierePageMenuOld.inscriere.path,
		element: <LANDING.INSCRIERE_OLD />,
	},
	{
		path: adminPageMenu.adminPage.path,
		element: <LANDING.ADMIN />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},
];
const contents = [...presentation];

export default contents;
