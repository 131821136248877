import React, { ReactNode, useEffect, useContext, useState } from 'react';
import axios from 'axios'
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {inscrierePageMenu,dashboardOLD,DashboardsInspector,DashboardsInformatician,DashboardsMRU,DashboardsCandidat,Dashboards,dashboardInspectorPagesMenu ,inscrierePageMenuOld, dashboardPagesMenu,adminPageMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Progress from '../../../components/bootstrap/Progress';
import { useNavigate } from "react-router-dom";


const DefaultAside = () => {
	const navigate = useNavigate();
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const [ eroareImport, setEroareImport] = useState(false);
	const [ contType , setContType] = useState(null);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);

	useEffect(() => {
		axios({
			method: "POST",
			url: process.env.REACT_APP_BACKEND_SERVER_URL + ":" + process.env.REACT_APP_BACKEND_SERVER_PORT + "/checkContType",
			data:{
				email: sessionStorage.getItem('E-Titularizare_Email'),
				contType : sessionStorage.getItem('E-Titularizare_ContType')
			},
			headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json"
			}
			}).then((res) => {
				setEroareImport(false);
				setContType(res.data[0].ContType)
			})
			// Handle the response from backend here
			.catch(() => {
				setEroareImport(true);
				sessionStorage.clear();
				localStorage.clear();
				navigate("/auth-pages/login") 
			});

	  }, []);
	return (

		<Aside>
			{eroareImport
			?<>
				<NavigationLine />
				{"Eroare la import"}
				<NavigationLine />
			</>
			:null
			}

			{contType === null
				?<Progress
				value= {100}
				height={ 10} // Example: 10, '3vh', '5rem' etc.
				isStriped={ true }
				isAnimated={ true }
				color={ 'primary' } />

				:<AsideBody>
					{contType === 0 //admin
						?<>
						<Navigation menu={Dashboards} id='aside-dashboard' />
						<Navigation menu={inscrierePageMenu} id='aside-inscriere' />
						<NavigationLine />
						<Navigation menu={dashboardPagesMenu} id='aside-dashboardold1' />
						<Navigation menu={inscrierePageMenuOld} id='aside-inscriereOld' />
						<Navigation menu={dashboardInspectorPagesMenu} id='aside-dashboardold2' />
						<Navigation menu={adminPageMenu} id='aside-admin' />
						<Navigation menu={dashboardOLD} id='old-dashboard' />

						</>

						:null
					}
					{contType === 1 //mru
						?<Navigation menu={DashboardsMRU} id='aside-dashboard' />
						:null
					}
					{contType === 2 //inspector
						?<Navigation menu={DashboardsInspector} id='aside-dashboard' />
						:null
					}
					{contType === 3 //informatician
						?<Navigation menu={DashboardsInformatician} id='aside-dashboard' />
						:null
					}
					{contType === 4 //candidat
						?<><Navigation menu={DashboardsCandidat} id='aside-dashboard' />
						</>
						:null
					}
				
				</AsideBody>
			}

{/*				{!doc && (
					<>
						<Navigation menu={demoPagesMenu} id='aside-demo-pages' />
						<NavigationLine />
						<Navigation menu={pageLayoutTypesPagesMenu} id='aside-menu' />
						<NavigationLine />
						<nav>
							<div className='navigation'>
								<div className='navigation-item'>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<span className='navigation-text'>
												<Popovers
													title='Aside.tsx'
													desc={
														<code>
															src/pages/_layout/_asides/DefaultAside.tsx
														</code>
													}>
													Aside
												</Popovers>
											</span>
										</span>
									</span>
								</div>
							</div>
						</nav>
					</>
				)}
*/}			
			<AsideFoot>

				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
