// prettier-ignore
import React, { useRef ,FC, useCallback, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes, { any } from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Select from '../../../components/bootstrap/forms/Select';
import Checks from '../../../components/bootstrap/forms/Checks';
import Option from '../../../components/bootstrap/Option';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import logo from '../../../assets/img/Sigla GDD - Proiect rev. 5.png';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import Modal, {ModalTitle, ModalHeader, ModalFooter, ModalBody} from '../../../components/bootstrap/Modal';


interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Creaza cont,</div>
				<div className='text-center h4 text-muted mb-5'>Inregistreaza-te pe platforma de Mobilitate</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Bine ai venit,</div>
			<div className='text-center h4 text-muted mb-5'>Intra in platforma de Mobilitate!</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const [numeR, setNumeR] = useState('');
	const [prenumeR, setPrenumeR] = useState('');
	const [emailR, setEmailR] = useState('');
	const [parolaR, setParolaR] = useState('');
	const [telefonR, setTelefonR] = useState('');
	const [cnpR, setCnpR] = useState('');
	const [serieCIR, setSerieCIR] = useState('');
	const [nrSerieCIR, setNrSerieCIR] = useState('');

	const [judetB, setJudetB] = useState('');
	const [localitateB, setLocalitateB] = useState('');
	const [stradaB, setStradaB] = useState('');
	const [nrStradaB, setNrStradaB] = useState('');
	const [blocB, setBlocB] = useState('');
	const [scaraB, setScaraB] = useState('');
	const [etajB, setEtajB] = useState('');
	const [apartamentB, setApartamentB] = useState('');

	const [sameAdress, setSameAdress] = useState(false);

	const [judetC, setJudetC] = useState('');
	const [localitateC, setLocalitateC] = useState('');
	const [stradaC, setStradaC] = useState('');
	const [nrStradaC, setNrStradaC] = useState('');
	const [blocC, setBlocC] = useState('');
	const [scaraC, setScaraC] = useState('');
	const [etajC, setEtajC] = useState('');
	const [apartamentC, setApartamentC] = useState('');

	const [judete, setJudete] = useState<any>([]);
	const [localitatiB, setLocalitatiB] = useState<any>([]);
	const [localitatiC, setLocalitatiC] = useState<any>([]);

	useEffect(() => {
		localStorage.clear();
		axios({
		  method: "GET",
		  url:"https://api-judete-localitati.gddservices.ro/judete",
		  headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json"
			},

		}).then((res) => {
			  if(res.status === 200){
				setJudete(res.data.judete);
			}
		 })
		 // Handle the response from backend here
		.catch((err) => {
		  console.log(err)
		 });
	  }, []);

	
	const sectoare =["Sector 1", "Sector 2", "Sector 3", "Sector 4", "Sector 5", "Sector 6"];

	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const usernameCheck = (username: string) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username: string, password: string) => {
		return getUserDataWithUsername(username).password === password;
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Necesar';
			}else if(!values.loginUsername.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ){
				errors.loginUsername = 'Email Invalid';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Necesar';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			// if (usernameCheck(values.loginUsername)) {
			// 	if (passwordCheck(values.loginUsername, values.loginPassword)) {
			// 		if (setUser) {
			// 			setUser(values.loginUsername);
			// 		}

			// 		handleOnClick();
			// 	} else {
			// 		formik.setFieldError('loginPassword', 'Parola incorecta!.');
			// 	}
			// }
			axios({
				method: "POST",
				url:"https://mobilitatebackend.gdds.ro:443/checkAccount",
				data:{
					email: values.loginUsername,
					parola: values.loginPassword
	
				},
				headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
				}
				}).then((res) => {
					if (res.data.length === 1){
						if (setUser) {
							setUser(values.loginUsername);
							sessionStorage.setItem('E-Titularizare_Nume', res.data[0].Nume);
							sessionStorage.setItem('E-Titularizare_Prenume', res.data[0].Prenume);
							sessionStorage.setItem('E-Titularizare_Email', res.data[0].Email);
							sessionStorage.setItem('E-Titularizare_Id', res.data[0].id);
							sessionStorage.setItem('E-Titularizare_ContType', res.data[0].ContType);
							localStorage.setItem('Nume', res.data[0].Nume);
							localStorage.setItem('Prenume', res.data[0].Prenume)
							localStorage.setItem('Email', res.data[0].Email)
							localStorage.setItem('Telefon', res.data[0].Telefon)
							localStorage.setItem('CNP', res.data[0].CNP)
							localStorage.setItem('SerieB', res.data[0].SerieB)
							localStorage.setItem('NumarB', res.data[0].NumarB)
							localStorage.setItem('JudetB', res.data[0].JudetB)
							localStorage.setItem('JudetC', res.data[0].JudetC)
							localStorage.setItem('LocalitateB', res.data[0].LocalitateB)
							localStorage.setItem('LocalitateC', res.data[0].LocalitateC)
							localStorage.setItem('StradaB', res.data[0].StradaB)
							localStorage.setItem('StradaC', res.data[0].StradaC)
							localStorage.setItem('NrStradaB', res.data[0].NrStradaB)
							localStorage.setItem('NrStradaC', res.data[0].NrStradaC)
							localStorage.setItem('BlocB', res.data[0].BlocB)
							localStorage.setItem('BlocC', res.data[0].BlocC)
							localStorage.setItem('ScaraB', res.data[0].ScaraB)
							localStorage.setItem('ScaraC', res.data[0].ScaraC)
							localStorage.setItem('EtajB', res.data[0].EtajB)
							localStorage.setItem('EtajC', res.data[0].EtajC)
							localStorage.setItem('ApartamentB', res.data[0].ApartamentB)
							localStorage.setItem('ApartamentC', res.data[0].ApartamentC)
							localStorage.setItem('DCreare', res.data[0].DCreare)
							localStorage.setItem('IdCont', res.data[0].id)
							localStorage.setItem('ContType', res.data[0].ContType)
							localStorage.setItem('DeActualizat', res.data[0].deActualizat)
						}
						handleOnClick();
					}else{
						formik.setFieldError('loginPassword', 'Parola incorecta!.');	
					}
				})
				// Handle the response from backend here
				.catch((err) => {
					formik.setFieldError('loginUsername', 'Eroare Server!');	
				});
		},
	});





	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [modalActivare, setModalActivare] = useState<boolean>(false);
	const [codOTP, setCodOTP] = useState<any>();
	const handleContinue = () => {
		setIsLoading(true);
		axios({
			method: "POST",
			url: "https://mobilitatebackend.gdds.ro:443/checkMail",
			data:{
				email: formik.values.loginUsername,

			},
			headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json"
			}
			}).then((res) => {
				if (res.data.rezultat[0].NrAdrese === 1){
					if(!res.data.activ[0].Activat){
						setModalActivare(true)
					}
					localStorage.setItem('facit_authUsername', formik.values.loginUsername);
					setSignInPassword(true);
					setIsLoading(false);
				}else{
					formik.setFieldError('loginUsername', 'Email incorect!');
					setIsLoading(false);

				}
			})
			// Handle the response from backend here
			.catch((err) => {
				formik.setFieldError('loginUsername', 'Eroare Server!');
				setIsLoading(false);

			});

	};

	const [msjOTP, setMsjOTP] = useState<any>()
	const handleTrimiteOTP = () => {
		axios({
			method: "POST",
			url: "https://mobilitatebackend.gdds.ro:443/ActivateAccount",
			data:{
				email: formik.values.loginUsername,
				otp: codOTP

			},
			headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json"
			}
			}).then((res) => {
				setModalActivare(false)
			})
			// Handle the response from backend here
			.catch((err) => {
				setMsjOTP("Cod Incorect!");

			});

	};

	const handleReTrimiteOTP = () => {
		axios({
			method: "POST",
			url: "https://mobilitatebackend.gdds.ro:443/retrimitereOTP",
			data:{
				email: formik.values.loginUsername,
			},
			headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json"
			}
			}).then((res) => {
				setMsjOTP('Cod retrimis')
			})
			// Handle the response from backend here
			.catch((err) => {
				setMsjOTP('Codul nu s-a retrimis...Incercati mai tarziu')

			});

	};


	const [eroareMSJ, setEroareMSJ] = useState("");
	const [eroare, setEroare] = useState(false);
	const handleVerificare = () => {
		setEroare(false);
		if(emailR === "" || !emailR.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ){
			setEroare(true);
			setEroareMSJ("la campul de: Email")
		}else if(prenumeR === ""){
			setEroare(true);
			setEroareMSJ("la campul de: Prenume")
		}else if(numeR === ""){
			setEroare(true);
			setEroareMSJ("la campul de: Nume")
		}else if(parolaR === ""){
			setEroare(true);
			setEroareMSJ("la campul de: Parola")
		}else if(telefonR === "" || telefonR.length > 14){
			setEroare(true);
			setEroareMSJ("la campul de: Telefon")
		}else if(cnpR === "" || cnpR.length !== 13){
			setEroare(true);
			setEroareMSJ("la campul de: CNP")
		}else if(serieCIR=== "" || serieCIR.length !== 2){
			setEroare(true);
			setEroareMSJ("la campul de: Serie CI")
		}else if(nrSerieCIR === "" || nrSerieCIR.length !== 6){
			setEroare(true);
			setEroareMSJ("la campul de: Numar Serie CI")
		}else if(judetB === "" || localitateB === "" || stradaB === "" || nrStradaB === ""){
			setEroare(true);
			setEroareMSJ("la campul de: Adresa CI : (Judet, Localitate, Strada, Nr.Strada) - Campuri Obligatorii")
		}
		else if(judetC === "" || localitateC === "" || stradaC === "" || nrStradaC === ""){
			setEroare(true);
			setEroareMSJ("la campul de: Adresa de Corespondenta : (Judet, Localitate, Strada, Nr.Strada) - Campuri Obligatorii")
		}else if(sameAdress){
			handleInregistrare(1);
		}else{
			handleInregistrare(0);
			}
		}

	const [isRegister, setIsRegister] = useState<boolean>(false);
	const handleInregistrare = (sameAdressNR: any) => {
		setIsRegister(true);
		axios({
			method: "POST",
			url:"https://mobilitatebackend.gdds.ro:443/inregistrare",
			data:{
				numeR:numeR,
				prenumeR: prenumeR,
				emailR: emailR,
				parolaR: parolaR,
				telefonR: telefonR,
				cnpR: cnpR,
				serieCIR: serieCIR,
				nrSerieCIR: nrSerieCIR,

				judetB: judetB,
				localitateB: localitateB,
				stradaB: stradaB,
				nrStradaB: nrStradaB,
				blocB: blocB,
				scaraB: scaraB,
				etajB: etajB,
				apartamentB: apartamentB,

				sameAdress: sameAdressNR,

				judetC: judetC,
				localitateC: localitateC,
				stradaC: stradaC,
				nrStradaC: nrStradaC,
				blocC: blocC,
				scaraC: scaraC,
				etajC: etajC,
				apartamentC: apartamentC
			},
			headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json"
			}
			}).then((res) => {
				console.log(res)
				if(res.data === 'Exista deja cont'){
					setIsRegister(false);
					setEroare(true);
					setEroareMSJ(": Exista deja un cont creat cu acest Email si/sau CNP")

				}else{
					handleOnClick();
				}
			})
			// Handle the response from backend here
			.catch((err) => {
			console.log(err)
			});
	}

	const [disOTPBut, setDisOTPBut]= useState<boolean>(true)
	const handleOTP = (e: any) =>{
		if(e.target.value.length < 5){
			setCodOTP(e.target.value)
			if(e.target.value.length === 4){
				setDisOTPBut(false)
			}else{
				setDisOTPBut(true)
			}
		}
	};

	const handleEmail = (e: any) =>{
		setEmailR(e.target.value)
	};
	const handleNume = (e: any) =>{
		if(e.target.value.toLowerCase().indexOf('ț') !== -1 || e.target.value.toLowerCase().indexOf('â') !== -1 || e.target.value.toLowerCase().indexOf('ă') !== -1 || e.target.value.toLowerCase().indexOf('ș') !== -1 || e.target.value.toLowerCase().indexOf('î') !== -1){
		}else{
			setNumeR(e.target.value.normalize( 'NFD' ))
		}
	};
	const handlePrenume = (e: any) =>{
		if(e.target.value.toLowerCase().indexOf('ț') !== -1 || e.target.value.toLowerCase().indexOf('â') !== -1 || e.target.value.toLowerCase().indexOf('ă') !== -1 || e.target.value.toLowerCase().indexOf('ș') !== -1 || e.target.value.toLowerCase().indexOf('î') !== -1){
		}else{
			setPrenumeR(e.target.value.normalize( 'NFD' ))
		}
	};
	const handleParola = (e: any) =>{
		setParolaR(e.target.value)
	};
	const handleTelefon = (e: any) =>{
		setTelefonR(e.target.value)
	};
	const handleCNP = (e: any) =>{
		setCnpR(e.target.value)
	};
	const handleSerie = (e: any) =>{
		setSerieCIR(e.target.value)
	};
	const handleNrSerie = (e: any) =>{
		setNrSerieCIR(e.target.value)
	};
	const handleStrada = (e: any, type: any) =>{
		if(sameAdress){
			setStradaB(e.target.value);
			setStradaC(e.target.value);
		}else{
			if(type === 0){
				setStradaB(e.target.value)

			}else{
				setStradaC(e.target.value)
			}
		}
	};
	const handleNrStrada = (e: any, type: any) =>{
		if(sameAdress){
			setNrStradaB(e.target.value);
			setNrStradaC(e.target.value);
		}else{
			if(type === 0){
				setNrStradaB(e.target.value)

			}else{
				setNrStradaC(e.target.value)
			}
		}
	};
	const handleBloc = (e: any, type: any) =>{
		if(sameAdress){
			setBlocB(e.target.value);
			setBlocC(e.target.value);
		}else{
			if(type === 0){
				setBlocB(e.target.value)

			}else{
				setBlocC(e.target.value)
			}
		}
	};
	const handleScara = (e: any, type: any) =>{
		if(sameAdress){
			setScaraB(e.target.value);
			setScaraC(e.target.value);
		}else{
			if(type === 0){
				setScaraB(e.target.value)

			}else{
				setScaraC(e.target.value)
			}
		}
	};
	const handleEtaj = (e: any, type: any) =>{
		if(sameAdress){
			setEtajB(e.target.value);
			setEtajC(e.target.value);
		}else{
			if(type === 0){
				setEtajB(e.target.value)

			}else{
				setEtajC(e.target.value)
			}
		}
	};
	const handleAp = (e: any, type: any) =>{
		if(sameAdress){
			setApartamentB(e.target.value);
			setApartamentC(e.target.value);
		}else{
			if(type === 0){
				setApartamentB(e.target.value)

			}else{
				setApartamentC(e.target.value)
			}
		}
	};
	const handlesameAdress = (state: any) => {
		setSameAdress(!state)
		if(!state){
			setJudetC(judetB);
			setLocalitateC(localitateB);
			setStradaC(stradaB);
			setNrStradaC(nrStradaB);
			setBlocC(blocB);
			setScaraC(scaraB);
			setEtajC(etajB);
			setApartamentC(apartamentB);
		}else if(state){
			setJudetC('');
			setLocalitateC('');
			setStradaC('');
			setNrStradaC('');
			setBlocC('');
			setScaraC('');
			setEtajC('');
			setApartamentC('');
		}
	}

	const handleJudet = (e: any, type: any) => {
		if(sameAdress){
			setJudetB(e.target.value)
			setJudetC(e.target.value)
			setLocalitateB('')
			setLocalitateC('')
			axios({
				method: "POST",
				url:"https://api-judete-localitati.gddservices.ro/localitati",
				data:{
					judet: e.target.value,
				},
				headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
				}
				}).then((res) => {
					setLocalitatiB(res.data)
					setLocalitatiC(res.data)
				})
				// Handle the response from backend here
				.catch((err) => {
					console.log(err)
				});
		}else{
			if(type === 0){
				setJudetB(e.target.value)
				setLocalitateB('')
				axios({
					method: "POST",
					url:"https://api-judete-localitati.gddservices.ro/localitati",
					data:{
						judet: e.target.value,
					},
					headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json"
					}
					}).then((res) => {
						setLocalitatiB(res.data)
					})
					// Handle the response from backend here
					.catch((err) => {
						console.log(err)
					});
			}else{
				setJudetC(e.target.value)
				setLocalitateC('')
				axios({
					method: "POST",
					url:"https://api-judete-localitati.gddservices.ro/localitati",
					data:{
						judet: e.target.value,
					},
					headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json"
					}
					}).then((res) => {
						setLocalitatiC(res.data)
					})
					// Handle the response from backend here
					.catch((err) => {
						console.log(err)
					});
			}
		}
	}

	const handleLocalitate = (e: any, type: any) =>{
		if(sameAdress){
			setLocalitateB(e.target.value);
			setLocalitateC(e.target.value);
		}else{
			if(type === 0){
				setLocalitateB(e.target.value)

			}else{
				setLocalitateC(e.target.value)
			}
		}
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<Modal
						id="modalCanditatura"
						isOpen={ modalActivare } // Example: state
						setIsOpen={ () => {setModalActivare(true)}}
						isScrollable={ true }
						isCentered={ true }
						size='xl' // 'sm' || 'lg' || 'xl'
						fullScreen='md' // true || 'sm' || 'md' || 'lg' || 'xl' || 'xxl'
						isAnimation={ true }>
						<ModalHeader className='modalCandiatura'>
							<h3>Contul necesita activare!</h3>
						</ModalHeader>
						<ModalBody className="ModalBody" style={{textAlign:'center', justifyContent:'center'}}>
							<h4>Codul de verificare a fost trimis la adresa: {formik.values.loginUsername}</h4>
							<h4> </h4>
								<Input style={{textAlign:'center',fontSize:'50px',width:'100%'}} type='number' value={codOTP} onChange={ e => {handleOTP(e)} }/>
							<h4 style={{color:'red'}}>Va rugam sa verificati si sectiunea de SPAM</h4>

						<div style={{border: '1px solid #000000'}}>
							<h4 style={{color:'red'}}>In caz ca nu ati primit codul, folositi butonul de mai jos pentru retrimitere</h4>
							<Button
								color={darkModeStatus ? 'light' : 'dark'}
								isLight={singUpStatus}
								className='rounded-3 w-30 text-align-center align-self-center justify-content-center'
								size='sm'
								onClick={() => {
									handleReTrimiteOTP();
								}}>
								Retrimite Cod
							</Button>
						</div>
						</ModalBody>
						<ModalFooter className="ModalFooter">
							<Button
								color={darkModeStatus ? 'light' : 'dark'}
								isLight={singUpStatus}
								className='rounded-1 w-100'
								size='lg'
								isDisable={disOTPBut}
								onClick={() => {
									handleTrimiteOTP();
								}}>
								Trimite
							</Button>
							<h4>{msjOTP}</h4>
						</ModalFooter>
					</Modal>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<div className='col 12'>
											<img className='text-center h1 fw-bold mt-5' style={{width:'50%'}} src={logo} alt="Logo" />
										</div>
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />
{/* 
								<Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong> {USERS.JOHN.username}
										</div>
										<div className='col-12'>
											<strong>Password:</strong> {USERS.JOHN.password}
										</div>
									</div>
								</Alert> */}
								<form className='row g-4'>
									{singUpStatus ? (
										<>

											<div className='col-12'>
												<FormGroup
													id='signup-email'
													isFloating
													label='Email'
													>
													<Input type='email' value={emailR} autoComplete='email' onChange={ e => {handleEmail(e)} } required/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-name'
													isFloating
													label='Prenume'>
													<Input type='text' value={prenumeR} autoComplete='given-name' onChange={ e => {handlePrenume(e)} } required/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-surname'
													isFloating
													label='Nume'>
													<Input type='text' invalidFeedback={"asdasd"} value={numeR}  autoComplete='family-name' onChange={ e => {handleNume(e)} } required/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-password'
													isFloating
													label='Password'>
													<Input  type='password' value={parolaR} autoComplete='password' onChange={e => {handleParola(e) } } required/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-tel'
													isFloating
													label='Telefon'>
													<Input  type='tel' value={telefonR} autoComplete='telefon' onChange={e => {handleTelefon(e) } } required/>
												</FormGroup>
											</div>

											<div className='col-12' style={{borderBottom:"3px solid #1f2128", borderRadius:'2px'}}/>

											<div className='col-12'>
												<FormGroup
													id='signup-CNP'
													isFloating
													label='CNP'>
													<Input type='number' value={cnpR} onChange={ e => {handleCNP(e)} } required/>
												</FormGroup>
											</div>
											<div className='col-4'>
												<FormGroup
													id='signup-serieCI'
													isFloating
													label='Serie CI'>
													<Input type='text' value={serieCIR} onChange={ e => {handleSerie(e)} } required/>
												</FormGroup>
											</div>
											<div className='col-8'>
												<FormGroup
													id='signup-serie'
													isFloating
													label='Numar CI'>
													<Input type='number' value={nrSerieCIR} onChange={ e => {handleNrSerie(e)} } required/>
												</FormGroup>
											</div>

											<div className='col-12' style={{borderBottom:"3px solid #1f2128", borderRadius:'2px'}}/>
											<div className='text-center h5 text-muted '>Adresa din CI (Carte de Identitate)</div>

											<Alert isLight icon='NotificationImportant' isDismissible>
												<div className='row'>
													<div className='col-12'>
														Daca oricare dintre adresele de mai jos <strong>nu</strong> contin:
													</div>
													<div className='col-12'>
														<strong>"Bloc", "Scara", "Etaj", "Ap."</strong> 
													</div>
													<div className='col-12'>
														<strong>Omiteti acele campuri!</strong> 
													</div>
												</div>
											</Alert>

											<div className='col-6'>
												<Select
													id='signup-judetB'
													title='Judet'
													placeholder='Judet'
													ariaLabel='Judet'
													value={String(judetB)}
            										onChange={(e) => handleJudet(e, 0)}
													required={true}>
													{judete.map((item: any,index: any) => (
													<Option key={index} value={item.Denumire}>
														{item.Denumire}
													</Option>
													))}
												</Select>
											</div>
											<div className='col-6'>
												{judetB === 'BUCURESTI'
													?<Select
														id='signup-localitateB'
														title='Localitate'
														placeholder='Localitate'
														ariaLabel='Localitate'
														value={localitateB}
            											onChange={(e) => handleLocalitate(e, 0)}
														required={true}>
														{sectoare.map((item: any,index: any) => (
														<Option key={index} value={item}>
															{item}
														</Option>
														))}
													</Select>
													:null
												}
												{judetB === ''
													?<Select
														id='signup-localitateB'
														title='Localitate'
														value={localitateB}
														placeholder='Localitate'
														ariaLabel='Localitate'
														disabled={true}
														required={true}>
													</Select>
													:null
												}
												{judetB !== '' && judetB !=='BUCURESTI'
													?<Select
														id='signup-localitateB'
														title='Localitate'
														placeholder='Localitate'
														ariaLabel='Localitate'
														value={localitateB}
            											onChange={(e) => handleLocalitate(e, 0)}
														required={true}>
														{localitatiB.map((item: any,index: any) => (
														<Option key={index} value={item.DenumireUAT}>
															{item.DenumireUAT}
														</Option>
														))}
													</Select>
													:null
												}

											</div>
											<div className='col-8'>
												<FormGroup
													id='signup-strada'
													isFloating
													aria-placeholder='Strada'
													label='Strada'>
													<Input type='text' placeholder='Strada' value={stradaB} onChange={ e => {handleStrada(e, 0)} } required/>
												</FormGroup>
											</div>
											<div className='col-4'>
												<FormGroup
													id='signup-NrStrada'
													isFloating
													aria-placeholder='Nr. Strada'
													label='Nr. Strada'>
													<Input type='text' placeholder='Nr. Strada' value={nrStradaB} onChange={ e => {handleNrStrada(e, 0)} } required/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<FormGroup
													id='signup-bloc'
													isFloating
													aria-placeholder='Bl.'
													label='Bl.'>
													<Input type='text' placeholder='Bl.' value={blocB} onChange={ e => {handleBloc(e, 0)} } required/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<FormGroup
													id='signup-scara'
													isFloating
													aria-placeholder='Sc.'
													label='Sc.'>
													<Input type='text' placeholder='Sc.' value={scaraB} onChange={ e => {handleScara(e, 0)} } required/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<FormGroup
													id='signup-etaj'
													isFloating
													aria-placeholder='Etaj'
													label='Etaj'>
													<Input type='text' placeholder='Etaj' value={etajB} onChange={ e => {handleEtaj(e, 0)} } required/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<FormGroup
													id='signup-ap'
													isFloating
													aria-placeholder='Ap.'
													label='Ap.'>
													<Input type='text' placeholder='Ap.' value={apartamentB} onChange={ e => {handleAp(e, 0)} } required/>
												</FormGroup>
											</div>

											<div className='text-center h5 text-muted '>Adresa de corespondenta</div>

											<div className='col-12'>
												<Checks
													id='signup-sameAdress'
													// name={ String }
													// className={ String }
													type='switch' // 'checkbox' || 'radio' || 'switch'
													label="Adresa de corespondenta este aceeasi cu cea din CI"
													checked={ sameAdress } 
													onChange={() => handlesameAdress(sameAdress) }
												/>
											</div>

											<div className='col-6'>
												<Select
													id='signup-judetC'
													title='Judet'
													placeholder='Judet'
													ariaLabel='Judet'
													value={judetC}
            										onChange={(e) => handleJudet(e, 1)}
													disabled={sameAdress}
													required={true}>
													{judete.map((item: any,index: any) => (
														<Option key={index} value={item.Denumire}>
															{item.Denumire}
														</Option>
													))}
												</Select>
											</div>
											<div className='col-6'>

												{judetC === 'BUCURESTI'
													?<Select
														id='signup-localitateC'
														title='Localitate'
														placeholder='Localitate'
														ariaLabel='Localitate'
														value={localitateC}
            											onChange={(e) => handleLocalitate(e, 1)}
														disabled={sameAdress}
														required={true}>
														{sectoare.map((item: any,index: any) => (
														<Option key={index} value={item}>
															{item}
														</Option>
														))}
													</Select>
													:null
												}
												{judetC === ''
													?<Select
														id='signup-localitateC'
														title='Localitate'
														placeholder='Localitate'
														value={localitateC}
														ariaLabel='Localitate'
														disabled={sameAdress || true}
														required={true}>
													</Select>
													:null
												}
												{judetC !== '' && judetC !=='BUCURESTI'
													?<Select
														id='signup-localitateC'
														title='Localitate'
														placeholder='Localitate'
														ariaLabel='Localitate'
														value={localitateC}
            											onChange={(e) => handleLocalitate(e, 1)}
														disabled={sameAdress}
														required={true}>
														{localitatiC.map((item: any,index: any) => (
														<Option key={index} value={item.DenumireUAT}>
															{item.DenumireUAT}
														</Option>
														))}
													</Select>
													:null
												}
											</div>
											<div className='col-8'>
												<FormGroup
													id='signup-strada'
													isFloating
													aria-placeholder='Strada'
													label='Strada'>
													<Input type='text' disabled={sameAdress} placeholder='Strada' value={stradaC} onChange={ e => {handleStrada(e, 1)} } required/>
												</FormGroup>
											</div>
											<div className='col-4'>
												<FormGroup
													id='signup-NrStrada'
													isFloating
													aria-placeholder='Nr. Strada'
													label='Nr. Strada'>
													<Input type='text' disabled={sameAdress} placeholder='Nr. Strada' value={nrStradaC} onChange={ e => {handleNrStrada(e, 1)} } required/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<FormGroup
													id='signup-bloc'
													isFloating
													aria-placeholder='Bl.'
													label='Bl.'>
													<Input type='text' disabled={sameAdress} placeholder='Bl.' value={blocC} onChange={ e => {handleBloc(e, 1)} } required/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<FormGroup
													id='signup-scara'
													isFloating
													aria-placeholder='Sc.'
													label='Sc.'>
													<Input type='text' disabled={sameAdress} placeholder='Sc.' value={scaraC} onChange={ e => {handleScara(e, 1)} } required/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<FormGroup
													id='signup-etaj'
													isFloating
													aria-placeholder='Etaj'
													label='Etaj'>
													<Input type='text' disabled={sameAdress} placeholder='Etaj' value={etajC} onChange={ e => {handleEtaj(e, 1)} } required/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<FormGroup
													id='signup-ap'
													isFloating
													aria-placeholder='Ap.'
													label='Ap.'>
													<Input type='text' disabled={sameAdress} placeholder='Ap.' value={apartamentC} onChange={ e => {handleAp(e, 1)} } required/>
												</FormGroup>
											</div>

											{eroare
											?<Alert isLight icon='Dangerous' color='danger'>
												<div className='row'>
													<div className='col-12'>
														Eroare <strong>{eroareMSJ}</strong> 
													</div>
												</div>
											</Alert>
											:null

											}

											<div className='col-12'>
												<Button
													color='info'
													type='button'
													className='w-100 py-3'
													isDisable={isRegister}
													onClick={handleVerificare}>
													{isRegister && (
														<Spinner isSmall inButton isGrow />
													)}
													Inregistreaza-te!
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Email'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {formik.values.loginUsername}.
													</div>
												)}
												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'
													className={classNames({
														'd-none': !signInPassword,
													})}>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.loginUsername}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Continua
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														Logare
													</Button>
												)}
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{!signInPassword && (
										<>
											{/* <div className='col-12 mt-3 text-center text-muted'>
												SAU
											</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div> */}
										</>
									)}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
