import React, { useState, useContext, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import { demoPagesMenu } from '../../menu';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import Modal, {ModalTitle, ModalHeader, ModalFooter, ModalBody} from '../../components/bootstrap/Modal';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import ThemeContext from '../../contexts/themeContext';
import Popovers from '../../components/bootstrap/Popovers';
import Alert from '../../components/bootstrap/Alert';
import Button from '../../components/bootstrap/Button';



const User = () => {
	const { width } = useWindowSize();
	const { setAsideStatus } = useContext(ThemeContext);
	const { userData, setUser } = useContext(AuthContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
	const [modal, setModal]=useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
				<Modal 
					id='modal_Profile' 
					isOpen={ modal } // Example: state
					setIsOpen={ () => setModal(true) } // Example: setState
					
					size='lg'>					
					<ModalHeader 
						className="HeaderModal"
						>
						<ModalTitle id='TitluModal'>{`${localStorage.getItem('Prenume')} ${localStorage.getItem('Nume')}`}</ModalTitle>
					</ModalHeader>
					<ModalBody className='ModalBody'>
						<div className='row g-4'>
							<div className='col-6'>
								<h3>
									Email: 
									<small className='text-muted'>
										{` ${localStorage.getItem('Email')} `}
									</small>
								</h3>
							</div>

							<div className='col-6'>
								<h3>
									Telefon: 
									<small className='text-muted'>
										{` ${localStorage.getItem('Telefon')} `}
									</small>
								</h3>
							</div>

							<div className='col-12' style={{borderBottom:"1px solid  #c1c0be", borderRadius:'2px'}}/>

							<div className='col-5'>
								<h3>
									CNP: 
									<small className='text-muted'>
										{` ${localStorage.getItem('CNP')} `}
									</small>
								</h3>
							</div>

							<div className='col-3'>
								<h3>
									Serie CI: 
									<small className='text-muted'>
										{` ${localStorage.getItem('SerieB')} `}
									</small>
								</h3>
							</div>

							<div className='col-4'>
								<h3>
									Numar CI: 
									<small className='text-muted'>
										{` ${localStorage.getItem('NumarB')} `}
									</small>
								</h3>
							</div>

							<div className='col-12' style={{borderBottom:"1px solid  #c1c0be", borderRadius:'2px'}}/>

							<div className='col-12'>
								<h3>
									Adresa din CI: 
									<small className='text-muted'>
									{` ${localStorage.getItem('JudetB')} , ${localStorage.getItem('LocalitateB')} , ${localStorage.getItem('StradaB')} , ${localStorage.getItem('NrStradaB')} , ${localStorage.getItem('BlocB')} , ${localStorage.getItem('ScaraB')} , ${localStorage.getItem('EtajB')} , ${localStorage.getItem('ApartamentB')}`}
									</small>
								</h3>
							</div>

							<div className='col-12'>
								<h3>
									Adresa de corespondenta: 
									<small className='text-muted'>
									{` ${localStorage.getItem('JudetC')} , ${localStorage.getItem('LocalitateC')} , ${localStorage.getItem('StradaC')} , ${localStorage.getItem('NrStradaC')} , ${localStorage.getItem('BlocC')} , ${localStorage.getItem('ScaraC')} , ${localStorage.getItem('EtajC')} , ${localStorage.getItem('ApartamentC')}`}
									</small>
								</h3>
							</div>
						</div>

					</ModalBody>
					<ModalFooter className='ModalFooter'>
						<div className='col-12'>
							<Alert isLight icon='NotificationImportant' >
								<div className='row'>
									<div className='col-12'>
										Serviciul de schimbare al datelor momentan<strong> INDISPONIBIL</strong> 
									</div>
								</div>
							</Alert>
						</div>
						<div className='row'>

						<div className='col-6'>
							<Button
								color='warning'
								className='w-100 py-3'>
								Cerere de schimbare a datelor!
							</Button>
						</div>
						<div className='col-6'>
							<Button
								color='primary'
								className='w-100 py-3'
								onClick={() => {setModal(!modal)}}>
								Inchide sectiunea
							</Button>
						</div>
						</div>
					</ModalFooter>
				</Modal>

			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-info'>
					<div className='user-name'>
							{`${localStorage.getItem('Nume')} ${localStorage.getItem('Prenume')}`}
					</div>
					<div className='user-sub-title'>
							{`${localStorage.getItem('Email')}`}
					</div>
				</div>
			</div>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`/`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<p className='navigation-text' onClick={() => {setModal(!modal)} }>
										Profile
									</p>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus
											? (t('menu:DarkMode') as ReactNode)
											: (t('menu:LightMode') as ReactNode)}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								if (setUser) {
									setUser('');
								}
								if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) {
									setAsideStatus(false);
								}
								navigate(`../${demoPagesMenu.login.path}`);
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
